import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const breadcrumbs = [
    { name: "Home", href: "/" },
    { name: frontmatter.title }
  ];
  const { areas = null } = frontmatter;
  return (
    <Layout
      breadcrumbs={breadcrumbs}
      pageTitle={frontmatter.title}
      preambleText={frontmatter.preambleText}
      metaDescription={frontmatter.metaDescription}
    >
      <Content dangerouslySetInnerHTML={{ __html: html }} />
      {areas && (
        <RetailersGrid>
          {areas.map(area => (
            <RetailerArea key={area.title}>
              <RetailerAreaTitle>{area.title}</RetailerAreaTitle>
              {area.shops && (
                <ul>
                  {area.shops.map(shop => (
                    <li key={shop.link}>
                      <a href={shop.link}>{shop.title}</a>
                    </li>
                  ))}
                </ul>
              )}
            </RetailerArea>
          ))}
        </RetailersGrid>
      )}
    </Layout>
  );
}

const RetailersGrid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

  margin-top: 1.5rem;
`;

const RetailerArea = styled.div``;

const RetailerAreaTitle = styled.h2`
  margin: 0;
`;

const Content = styled.div`
  max-width: 60ch;
`;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        preambleText
        metaDescription
        areas {
          title
          shops {
            title
            link
          }
        }
      }
    }
  }
`;
